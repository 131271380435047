import React from 'react';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { Box, Grid, Typography } from "@material-ui/core";

const School = () => {
  return (
    <>
      <Navbar />

      <div className="container school-friends-page">
        <section className="section">
          <h2>Academic Journey</h2>
          <Grid container spacing={2} className="birth-prole-mainbox">
            {/* Box 1 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                p={3}
                >
                <div className="birthdayimg1">
                  <img
                    src="./images/cultural-img-2.jpeg"
                    alt="Padala Adharva Reddy"
                  />
                </div>
              </Box>
            </Grid>

            {/* Box 2 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography>
      
            Adharva's educational journey began in 2019 at Regal Ford International School, where he started LKG.
            His early school years were marked by his curiosity and eagerness to learn.
            Despite the challenges posed by the pandemic in 2020, Adharva transitioned smoothly to online classes
            for UKG at Meridian International School, showcasing his adaptability and resilience.

          </Typography>
            </Grid>
          </Grid>

          
        </section>

        <section className="section">
          <h2>Go-Karting Enthusiast</h2>

          <Grid container spacing={2} className="birth-prole-mainbox">
            {/* Box 1 */}

            <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography>
      
            In 2021, Adharva discovered his passion for go-karting.
            He started learning the sport and quickly excelled, impressing his instructors with his skills and dedication.
            This newfound interest not only provided a fun outlet but also taught him valuable lessons in discipline and focus.

          </Typography>
            </Grid>


            {/* Box 2 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                p={3}
                >
                <div className="birthdayimg1">
                  <img
                    src="./images/car-1-world-record2.jpeg"
                    alt="Padala Adharva Reddy"
                  />
                </div>
              </Box>
            </Grid>
            
          </Grid>

        </section>

        <section className="section">
          <h2>Sports Excellence</h2>
          <p>
            Recognizing Adharva’s potential in sports, Meridian International School awarded him a fifty percent fee reduction
            for 2022, a testament to his hard work and talent.
            This support has allowed him to pursue his passion for sports alongside his academic commitments.
          </p>
        </section>

        <section className="section">
          <h2>Academic Achievements</h2>
          <p>
            Adharva is known for his exceptional academic performance.
            His teachers praise his dedication, discipline, and enthusiasm for learning.
            He consistently achieves high grades and participates actively in class, often helping his classmates with their studies.
          </p>
        </section>

        <section className="section">
          <h2>Friendships and Social Life</h2>

          <Grid container spacing={2} className="birth-prole-mainbox">
            {/* Box 1 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                p={3}
                >
                <div className="birthdayimg1">
                  <img
                    src="./images/adar-childpics-3.jpeg"
                    alt="Padala Adharva Reddy"
                  />
                </div>
              </Box>
            </Grid>

            {/* Box 2 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography>
      
            Adharva has a close-knit group of friends who admire his leadership qualities and kind nature.
            He enjoys spending time with them, whether it’s studying together, playing sports, or simply having fun.
            His friends look up to him as a role model and are inspired by his achievements and positive attitude.

          </Typography>
            </Grid>
          </Grid>

        </section>

        <section className="section">
          <h2>School & Cultural Activities</h2>

          <Grid container spacing={2} className="birth-prole-mainbox">
            {/* Box 1 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                p={3}
                >
                <div className="birthdayimg1">
                  <img 
                    src="./images/school-cult.jpeg"
                    alt="Padala Adharva Reddy"
                  />
                </div>
              </Box>
            </Grid>

            {/* Box 2 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography>
      
            <strong>Artistic Talents:</strong> Adharva is a gifted artist with a passion for dance, singing, and painting.
            His performances in school cultural events have earned him numerous accolades, showcasing his ability to connect
            with audiences through his artistic expressions. He has a natural talent for evoking emotions, making his performances memorable and impactful.

          </Typography>
            </Grid>
          </Grid>

          <p>
            <strong>Cultural Ambassador:</strong> Through his involvement in cultural activities, Adharva promotes understanding
            and appreciation of diverse traditions. He has participated in various cultural festivals, performing traditional dances
            and showcasing his artwork, which often reflects themes of unity and harmony.
          </p>
        </section>

        <section className="section">
          <h2>Awards and Recognitions</h2>
          <ul>
            <li>MBC 2022 Show Topper: Adharva was the highlight of the MBC 2022 fashion show, where his unique fashion designs captured the audience's attention.</li>
            <li>Fashion Week Juniors: Adharva's innovative designs won him the Fashion Week Juniors competition, a significant achievement that opened doors for collaborations with established designers.</li>
          </ul>
        </section>

        <section className="section">
          <h2>Impact on Community</h2>
          <p>
            Adharva’s artistic endeavors have a profound impact on his community, bringing people together and fostering a sense of cultural pride.
            His performances and exhibitions serve as a platform for cultural exchange, inspiring others to appreciate and celebrate their heritage.
          </p>
        </section>
      </div>

      <Footer />
    </>
  );
}

export default School;
