import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

const FamilyGatherings = () => {
  return (
    <>
      <Navbar />

      <div className="container family-gatherings-page">
        <h2>Family & Gatherings</h2>

        <div className='img-common-landscape'>
            <img src='./images/family2.jpeg' />
          </div>

        <div className="family-event">
          <h3>Active Participation in Family Events</h3>
          <p>
            Adharva is an enthusiastic participant in family gatherings, functions, and festivals. He brings joy and energy to these occasions, whether it's through storytelling, playing games, or helping with decorations. His involvement helps create a warm and welcoming atmosphere, strengthening family bonds.
          </p>
        </div>

        <div className="family-event">
          <h3>Traditional and Cultural Engagement</h3>
          <p>
            Adharva actively participates in traditional rituals and celebrations, contributing to the preservation and appreciation of his cultural heritage. He enjoys performing traditional dances and assisting in preparations for festivals, making each event special and memorable.
          </p>
        </div>

        <div className="family-event">
          <h3>Impact on Family Dynamics</h3>
          <p>
            Adharva’s positive attitude and enthusiasm have a significant impact on his family, fostering a sense of unity and togetherness. His active participation in family events strengthens relationships and creates lasting memories that his family cherishes.
          </p>
        </div>

        <div className="family-event">
          <h3>Future Plans</h3>
          <p>
            As Adharva grows older, he hopes to continue being an integral part of family celebrations, contributing to the joy and harmony of these occasions. His commitment to family values and traditions reflects his deep-rooted respect for his heritage.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default FamilyGatherings;
