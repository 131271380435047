import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

const WorldRecords = () => {
  return (
    <>
      <Navbar />

      <div className="container world-records-page">
        <h2>World Records</h2>

        <div className='img-common-landscape'>
            <img src='./images/press.jpeg' />
          </div>


        <div className="world-record">
          <h3>Go-Karting World Record</h3>
          <p>
            In November 2021, Adharva set a world record as the youngest person to complete a ten-kilometer race in seventeen minutes and thirty-two seconds. This remarkable feat highlights his exceptional talent and determination in go-karting, setting a new benchmark for young racers worldwide.
          </p>
        </div>

        <div className="world-record">
          <h3>Archery World Record</h3>
          <p>
            Adharva broke the world record in archery by shooting 1,110 arrows in three hours and twenty-nine minutes. He surpassed this record by hitting 1,111 arrows in just three hours and ten minutes. This achievement showcases his exceptional skill, focus, and dedication to the sport.
          </p>
        </div>

        <div className="world-record">
          <h3>Inspiration and Legacy</h3>
          <p>
            Adharva’s world records serve as an inspiration to aspiring athletes, demonstrating that age is no barrier to achieving greatness. His accomplishments encourage young people to pursue their passions and strive for excellence in their chosen fields.
          </p>
        </div>

        <div className="world-record">
          <h3>Future Aspirations</h3>
          <p>
            Adharva aims to continue breaking records and achieving new milestones in both go-karting and archery. His dedication to these sports and his unwavering commitment to improvement set a positive example for others to follow.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default WorldRecords;
