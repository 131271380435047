import React from "react";
import "../style.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Box, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Navbar />

      <div className="container">
        <div className="header">
          <h1>PADALA ADHARVA REDDY</h1>
        </div>
        <div className="content">
          <Grid container spacing={2} className="home-prole-mainbox">
            {/* Box 1 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                p={3}
                >
                <div className="homeimg1">
                  <img
                    src="./images/adharva2.jpeg"
                    alt="Padala Adharva Reddy"
                  />
                </div>
              </Box>
            </Grid>

            {/* Box 2 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                className="home-profile"
                p={3}
                >
                <h2>Profile</h2>
                <ul>
                  <li>
                    <strong>Name:</strong> Padala Adharva Reddy
                  </li>
                  <li>
                    <strong>Father's Name:</strong> Padala Karthik Reddy
                  </li>
                  <li>
                    <strong>Mother's Name:</strong> Padala Arika
                  </li>
                  <li>
                    <strong>Paternal Grandparents:</strong> Padala Chandra
                    Sekhar Reddy, Padala Shanti Reddy
                  </li>
                  <li>
                    <strong>Maternal Grandparents:</strong> Kuthuru Rajeshwar
                    Reddy, Kuthuru Sriyutha
                  </li>
                  <li>
                    <strong>Date of Birth:</strong> March 8, 2015
                  </li>
                  <li>
                    <strong>Place of Birth:</strong> Hyderabad
                  </li>
                  <li>
                    <strong>Sports:</strong> Archery, Go-Karting
                  </li>
                  <li>
                    <strong>School:</strong> LKG - Regal Ford International
                    School; UKG to Present - Meridian International School
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{marginTop:'30px'}}>
            {/* Box 1 */}
            <Grid item xs={12} md={4} lg={4} xl={4}>
            <Link to="/achievements" style={{textDecoration:'none',color:'#000'}}><Box className="home-achive-btn"
                p={3}
                
                >
                <Typography >Achievements & Awards</Typography>
              </Box>
              </Link>
            </Grid>

            {/* Box 2 */}
            <Grid item xs={12} md={4} lg={4} xl={4}>
            <Link to="/worldrecords" style={{textDecoration:'none',color:'#000'}}><Box className="home-achive-btn"
                p={3}
                bgcolor="#ffffff"
                >
                <Typography>World Records</Typography>
              </Box>
              </Link>
            </Grid>

            {/* Box 3 */}
            <Grid item xs={12} md={4} lg={4} xl={4}>
            <Link to="/tourstravels" style={{textDecoration:'none',color:'#000'}}><Box className="home-achive-btn"
                p={3}
                
                >
                
                <Typography>Tours & Travels</Typography>
              </Box>
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
