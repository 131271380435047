import React from 'react';

import Navbar from '../Navbar';
import Footer from '../Footer';
import { Box, Grid, Typography } from "@material-ui/core";


const Birthday = () => {
  return (
    <>
      <Navbar />

      <div className="container birthdays-page">
        <section className="birthday-section">
          <h2 style={{textAlign:'center',textTransform:'uppercase'}}>Cradle Ceremony & First Birthday</h2>

          <Grid container spacing={2} className="birth-prole-mainbox">
            {/* Box 1 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                p={3}
                >
                <div className="birthdayimg1">
                  <img
                    src="./images/adharva-pic-page2.jpeg"
                    alt="Padala Adharva Reddy"
                  />
                </div>
              </Box>
            </Grid>

            {/* Box 2 */}
            <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography>
            Adharva was born on March 8, 2015, in Hyderabad to his proud parents Karthik and Arika.
            His cradle ceremony took place on July 25, 2015, at Sama Yadi Reddy Gardens in Balapur,
            marking the first major celebration in his life. The ceremony was filled with joy, laughter,
            and traditional rituals, attended by close family and friends who showered him with blessings and love.
          </Typography>
            </Grid>
          </Grid>


          
          <h3>First Birthday Celebration:</h3>
          <p>
            On March 8, 2016, Adharva's first birthday was celebrated at NTR Gardens, Tankbund,
            with a grand car-themed party. The picturesque setting provided a perfect backdrop for
            the festivities, which included exciting games like balloon pop and Pin the Tail on the Donkey,
            face painting, and a photo booth. The highlight of the day was a magnificent cake cutting ceremony,
            surrounded by delighted guests.
          </p>
          <div className='img-common-landscape'>
            <img src='./images/kar-adr-p3-2.jpeg' />
          </div>
          <h3>Cultural Festivities:</h3>
          <p>
            The celebration also featured cultural performances, with dancers and musicians showcasing traditional dances,
            adding a rich cultural flavor to the event. There were also live art demonstrations, storytelling sessions,
            and craft stations where children could engage in creative activities like making rangoli designs and paper crafts,
            making the day memorable for everyone.
          </p>
        </section>

        <section className="birthday-section">
          <h2>Subsequent Birthdays</h2>
          <p>
            Each year, Adharva’s birthdays have been celebrated with equal enthusiasm,
            featuring different themes and activities that reflect his growing interests and passions.
            From superhero-themed parties to sports extravaganzas, every birthday is a new adventure for Adharva and his loved ones.
          </p>
        </section>
      </div>

      <Footer />
    </>
  );
}

export default Birthday;
