import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';

const More = () => {
  return (
    <>
      <Navbar />

      <div className="container more-page">
        <h2>Explore More</h2>

        <div className="links">
          <Link to="/achievements" className="link-item">
            Achievements & Awards
          </Link>
          <Link to="/worldrecords" className="link-item">
            World Records
          </Link>
          <Link to="/familygatherings" className="link-item">
            Family & Gatherings
          </Link>
          <Link to="/tourstravels" className="link-item">
            Tours & Travels
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default More;
