import React from 'react'

const Footer = () => {
  return (
    <>
    <div className="footer">
        <p>&copy; 2024 LifeHisto.com Pvt Ltd. All rights reserved.</p>
      </div>
    </>
  )
}

export default Footer