import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

const ToursTravels = () => {
  return (
    <>
      <Navbar />

      <div className="container tours-travels-page">
        <h2>Tours & Travels</h2>

        <div className='img-common-landscape'>
            <img src='./images/family.jpeg' />
          </div>
          

        <div className="travel-event">
          <h3>Exploration and Adventure</h3>
          <p>
            Adharva's travels have taken him to various fascinating places, where he has explored diverse cultures and natural wonders. His adventures have provided him with unique experiences, from venturing into jungles and mountains to witnessing wildlife in their natural habitats.
          </p>
        </div>

        <div className="travel-event">
          <h3>Cultural Exposure</h3>
          <p>
            During his travels, Adharva has experienced different cultures, met new people, and tasted unique cuisines. His openness to learning about different ways of life has broadened his perspective and deepened his appreciation for the diversity of the world.
          </p>
        </div>

        <div className="travel-event">
          <h3>Impact of Travel</h3>
          <p>
            Traveling has enriched Adharva both personally and intellectually. It has allowed him to gain valuable insights into different lifestyles and cultures, fostering connections between communities and promoting a sense of global understanding.
          </p>
        </div>

        <div className="travel-event">
          <h3>Future Travel Goals</h3>
          <p>
            Adharva aspires to continue exploring the world, experiencing new cultures, and building connections with people from diverse backgrounds. His travels inspire others to embrace the world’s diversity and explore its wonders with an open mind.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ToursTravels;
