import React from "react";
import "./App.css";
import Home from "./components/Home";
import { Route, Routes } from "react-router-dom";
import Birthday from "./components/Pages/Birthday";
import School from "./components/Pages/School";
import More from "./components/Pages/More";
import Achievements from "./components/Pages/Achievements";
import WorldRecords from "./components/Pages/WorldRecords";
import ToursTravels from "./components/Pages/ToursTravels";
import FamilyGatherings from "./components/Pages/FamilyGatherings";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/birthday" element={<Birthday />} />
        <Route path="/school" element={<School />} />
        <Route path="/more" element={<More />} />
        <Route path="/achievements" element={<Achievements />} />
        <Route path="/worldrecords" element={<WorldRecords />} />
        <Route path="/tourstravels" element={<ToursTravels />} />
        <Route path="/familygatherings" element={<FamilyGatherings />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
