import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

const Achievements = () => {
  return (
    <>
      <Navbar />

      <div className="container achievements-page">
        <h2>Achievements & Awards</h2>

        <div className='img-common-landscape'>
            <img src='./images/car-1-world-record.jpeg' />
          </div>



        <div className="achievement">
          <h3>Krida Bhushan Puraskar</h3>
          <p>
            Adharva’s outstanding achievements in archery earned him the prestigious Krida Bhushan Puraskar. This award, presented by Dr. Padmashree Sai Baba Gaud, recognizes his relentless pursuit of excellence and his commitment to the sport. It stands as a testament to his hard work and serves as an inspiration to young athletes around the world.
          </p>
        </div>

        <div className="achievement">
          <h3>Award for Sports Excellence 2023</h3>
          <p>
            In 2023, Adharva was honored with the Award for Sports Excellence, highlighting his significant contributions to sports, particularly archery and go-karting. This accolade underscores his dedication and potential for future success in these fields.
          </p>
        </div>

        <div className="achievement">
          <h3>Notable Achievements</h3>
          <p>
            Adharva’s journey is marked by numerous accomplishments, from setting world records in sports to excelling in academics and arts. His unwavering determination and passion for learning and growth continue to drive him towards new heights.
          </p>
        </div>

        <div className="achievement">
          <h3>Inspiration to Others</h3>
          <p>
            Adharva’s achievements inspire others to pursue their dreams with dedication and perseverance. His story demonstrates that with hard work and support, one can achieve remarkable success at a young age.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Achievements;
